import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enAbout from 'locales/en/about';
import enAi from 'locales/en/ai';
import enCalendar from 'locales/en/calendar';
import enCommon from 'locales/en/common';
import enCompany from 'locales/en/company';
import enErrors from 'locales/en/errors';
import enForms from 'locales/en/forms';
import enHome from 'locales/en/home';
import enPlans from 'locales/en/plans';
import enSeo from 'locales/en/seo';
import enWizardForm from 'locales/en/wizardForm';
import enError from 'locales/en/error';
import esAbout from 'locales/es/about';
import esAi from 'locales/es/ai';
import esCalendar from 'locales/es/calendar';
import esCommon from 'locales/es/common';
import esCompany from 'locales/es/company';
import esErrors from 'locales/es/errors';
import esForms from 'locales/es/forms';
import esHome from 'locales/es/home';
import esPlans from 'locales/es/plans';
import esSeo from 'locales/es/seo';
import esWizardForm from 'locales/es/wizardForm';
import esError from 'locales/es/error';
import heAbout from 'locales/he/about';
import heAi from 'locales/he/ai';
import heCalendar from 'locales/he/calendar';
import heCommon from 'locales/he/common';
import heCompany from 'locales/he/company';
import heErrors from 'locales/he/errors';
import heForms from 'locales/he/forms';
import heHome from 'locales/he/home';
import hePlans from 'locales/he/plans';
import heSeo from 'locales/he/seo';
import heError from 'locales/he/error';
import ptAbout from 'locales/pt/about';
import ptAi from 'locales/pt/ai';
import ptCalendar from 'locales/pt/calendar';
import ptCommon from 'locales/pt/common';
import ptCompany from 'locales/pt/company';
import ptErrors from 'locales/pt/errors';
import ptForms from 'locales/pt/forms';
import ptHome from 'locales/pt/home';
import ptPlans from 'locales/pt/plans';
import ptSeo from 'locales/pt/seo';
import ptWizardForm from 'locales/pt/wizardForm';
import ptError from 'locales/pt/error';
import enCialScores from 'locales/en/cialScores.json';
import esCialScores from 'locales/es/cialScores.json';
import ptCialScores from 'locales/pt/cialScores.json';

const resources = {
  en: {
    common: enCommon,
    home: enHome,
    plans: enPlans,
    forms: enForms,
    about: enAbout,
    company: enCompany,
    errors: enErrors,
    calendar: enCalendar,
    seo: enSeo,
    ai: enAi,
    wizardForm: enWizardForm,
    error: enError,
    cialScores: enCialScores,
  },
  es: {
    common: esCommon,
    home: esHome,
    plans: esPlans,
    forms: esForms,
    about: esAbout,
    company: esCompany,
    errors: esErrors,
    calendar: esCalendar,
    seo: esSeo,
    ai: esAi,
    wizardForm: esWizardForm,
    error: esError,
    cialScores: esCialScores,
  },
  pt: {
    common: ptCommon,
    home: ptHome,
    plans: ptPlans,
    forms: ptForms,
    about: ptAbout,
    company: ptCompany,
    errors: heErrors,
    calendar: ptCalendar,
    seo: ptSeo,
    ai: ptAi,
    wizardForm: ptWizardForm,
    error: ptError,
    cialScores: ptCialScores,
  },
  he: {
    common: heCommon,
    home: heHome,
    plans: hePlans,
    forms: heForms,
    about: heAbout,
    company: heCompany,
    errors: ptErrors,
    calendar: heCalendar,
    seo: heSeo,
    ai: heAi,
    error: heError,
  },
};

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  fallbackNS: 'common',
});

i18n.services.formatter.add('required', (value) => {
  return `${value ? ' *' : ''}`;
});

export default i18n;
