import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isOpenChatAI: false,
  isChatAIEnabled: false,
  questionChatAI: '',
  isSettingsTabOpen: false,
};

const aiChatAssistant = createSlice({
  name: 'aiChatAssistant',
  initialState,
  reducers: {
    toggleChatAI: (state) => {
      state.isOpenChatAI = !state.isOpenChatAI;
    },
    showChatAI: (state, action) => {
      state.isOpenChatAI = action.payload.show;
      state.questionChatAI = action.payload.questionChatAI;
    },
    showSettings: (state, action) => {
      state.isOpenChatAI = true;
      state.isSettingsTabOpen = action.payload.show;
    },
    setChatAIEnabled: (state, action) => {
      state.isChatAIEnabled = action.payload;
    },
    toggleChatAIEnabled: (state) => {
      state.isChatAIEnabled = !state.isChatAIEnabled;
      if (state.isChatAIEnabled) {
        state.isSettingsTabOpen = false;
      }
    },
  },
});

export const { reducer, name: reducerPath } = aiChatAssistant;
export const {
  toggleChatAI,
  showChatAI,
  showSettings,
  isSettingsTabOpen,
  isChatAIEnabled,
  toggleChatAIEnabled,
  setChatAIEnabled,
} = aiChatAssistant.actions;
