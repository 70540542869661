import { configureStore } from '@reduxjs/toolkit';
import { createWrapper } from 'next-redux-wrapper';

import { api } from './api';
import { reducer as aiChatAssistantReducer, reducerPath as aiChatAssistantReducerPath } from './aiChatAssistant';

export const makeStore = () =>
  configureStore({
    reducer: {
      [api.reducerPath]: api.reducer,
      [aiChatAssistantReducerPath]: aiChatAssistantReducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware),
  });

export const wrapper = createWrapper(makeStore, {
  // https://github.com/kirill-konshin/next-redux-wrapper/issues/276
  serializeState: (state) => JSON.stringify(state),
  deserializeState: (state) => JSON.parse(state),
});
