import { SharepointVerificationFailedReasonEnum } from '@app/library/dto';

export enum FileUploadStatus {
  SUCCESS = 'success',
  FAILED = 'failed',
  PENDING = 'pending',
}

export class CompanyDto {
  companyName: string;
  companyLegalName: string;
  taxId: string;
  phoneNumber: string;
  website?: string;
  numberOfEmployees: number;
  annualSales: number;
  annualSalesCurrency: string;
  address1: string;
  address2?: string;
  city: string;
  province: string;
  postcode: string;
  countryCode: string;
}

export enum SelectedPlanEnum {
  DUNS_ACCESS = 'duns_access',
  DUNS_BASIC = 'duns_basic',
  DUNS_SILVER = 'duns_silver',
  DUNS_GOLD = 'duns_gold',
}

export enum TaskType {
  CompanyInfo = 'company_info', // This step is to select the company info
  CompanyVerification = 'company_verification', // This step is to verify company against CIEC or manual upload documentation
  SelectPlan = 'select_plan', // This step is to choose a plan (the default one will be the `DUNS Access`)
  DunsDataProfile = 'duns_data_profile', // This step is to download, fill and upload the BIR form filled
}

export enum TaskStatus {
  NotStarted = 'not_started',
  Pending = 'pending',
  Completed = 'completed',
  Failed = 'failed',
  Expired = 'expired',
  Finalized = 'finalized',
  Canceled = 'canceled',
  Denied = 'denied',
}

export enum TaskActor {
  User = 'user',
  System = 'system',
}

export enum TaskErrorMessageId {
  TASK_MANUAL_VERIFICATION_FAILED = 'workflows.task_manual_verification_failed',
  TASK_AUTOMATIC_VERIFICATION_FAILED = 'workflows.task_automatic_verification_failed',
}

export enum SharepointVerificationMethodEnum {
  DOCUMENTS = 'documents',
  SAT_AUTHORIZATION = 'sat_authorization',
}

interface CompanyInfoMetadata {
  aclCompanyId?: string;
  sharepointId?: number;
  encryptedDuns?: string;
  company?: CompanyDto | null;
}
interface SelectPlanMetadata {
  selectedPlan?: SelectedPlanEnum;
}
interface CompanyVerificationMetadata {
  verifiedRfc?: string;
  uploadedDocs?: string[];
  verificationMethod?: SharepointVerificationMethodEnum;
  errorCode?: SharepointVerificationFailedReasonEnum;
}
interface DunsDataProfileMetadata {
  uploadedFiles?: { index: number; name: string; status: FileUploadStatus }[];
}

export type TaskMetadata = CompanyInfoMetadata &
  SelectPlanMetadata &
  CompanyVerificationMetadata &
  DunsDataProfileMetadata;

export interface MetaData {
  reference?: string;
}

export interface Task {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  workflowId: string;
  parentId: string | null;
  parent: Task | null;
  order: number;
  type: TaskType;
  status: TaskStatus;
  actor: TaskActor;
  metadata: TaskMetadata | null;
  dependencies: TaskType[] | null;
  children: Task[];
}
