/* eslint-disable camelcase */

import React, { useEffect } from 'react';

import Cookies from 'js-cookie';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { DefaultSeo } from 'next-seo';
import posthog from 'posthog-js';
import { PostHogProvider, usePostHog } from 'posthog-js/react';
import PropTypes from 'prop-types';
import { I18nextProvider } from 'react-i18next';
import { ToastContainer } from 'react-toastify';

import InternationalizationContextProvider from 'components/_context/InternationalizationContext';
import IsLoggedInContextProvider from 'components/_context/IsLoggedInContext';
import RouterContextProvider from 'components/_context/RouterContext';
import { wrapper } from 'store';
import { useGetUserQuery, USER_CACHE_KEY } from 'store/api';
import { useWindowSize } from 'utils/hooks';
import i18n from 'utils/i18n';
import { getLocale } from 'utils/locale';
import { getTranslated } from 'utils/localization';
import { CookieApiHeader } from 'utils/structureConstants';

import CookieNames from 'constants/cookieNames';

import 'styles/globals.scss';
import { OfficeType } from 'store/models/Company';
import { ProductStatusEnum } from 'constants/CompanyHomeEnum';

// Check that PostHog is client-side (used to handle Next.js SSR)
if (typeof window !== 'undefined') {
  if (process.env.NEXT_PUBLIC_POSTHOG_KEY) {
    posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
      api_host: 'https://app.posthog.com',
      // Enable debug mode in development
      loaded: (posthog) => {
        if (process.env.NODE_ENV === 'development') posthog.debug();
      },
      disable_session_recording: true,
      autocapture: false,
      capture_pageview: false,
      capture_pageleave: false,
    });
  }
}

if (process.env.USE_MOCKS === 'true') {
  require('mocks'); // eslint-disable-line global-require
}

const propTypes = {
  Component: PropTypes.func,
  pageProps: PropTypes.object,
};

function getContextFrom_URL_or_SSRprops(router, contextFromSSR) {
  if (typeof window !== 'undefined') {
    const host = new URL(window.location.origin).host;
    const languageParam = router.query.language;
    const { language } = getLocale({ language: languageParam });
    return {
      host,
      language,
      isLoggedIn: Cookies.get(CookieNames.isLoggedIn) === 'true',
      geo: Cookies.get(CookieNames.geo) || null,
      selectedLanguage: Cookies.get(CookieNames.selectedLanguage) || null,
      country: Cookies.get(CookieNames.country) || null,
    };
  }
  return {
    language: contextFromSSR.language,
    host: contextFromSSR.host,
    isLoggedIn: contextFromSSR.isLoggedIn,
    geo: contextFromSSR.geo,
    selectedLanguage: contextFromSSR.selectedLanguage,
    country: contextFromSSR.country,
  };
}

function getI18nInstance(i18n, currentLanguage) {
  if (i18n.language === currentLanguage) {
    return i18n;
  }
  i18n.changeLanguage(currentLanguage);
  return i18n;
}

const MyApp = ({ Component, pageProps }) => {
  const { contextFromSSR = {}, ...restPageProps } = pageProps;

  const router = useRouter();
  const posthog = usePostHog();
  const windowSize = useWindowSize();
  const languageParam = router.query.language;

  const { host, language, country, isLoggedIn, geo, selectedLanguage } = getContextFrom_URL_or_SSRprops(
    router,
    contextFromSSR,
  );

  const { data: user } = useGetUserQuery(USER_CACHE_KEY, { skip: !isLoggedIn });

  // Track page views for GA
  useEffect(() => {
    const handleRouteChange = () => {
      if (window?.analytics) {
        window?.analytics?.page();
      }
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    if (user) {
      window?.analytics?.identify(user.email, {
        email: user.email,
        name: user.name,
        first_name: user.firstName,
        firstName: user.firstName,
        last_name: user.lastName,
        lastName: user.lastName,
        language: user.language,
        aclId: user.aclId,
        companies: user.companies.map((company) => ({
          companyId: company.aclCompanyId,
          slug: company.slug,
          peopleInCompany: company.aclCompanyId.peopleInCompany,
          plan: company.homeStatus.plan,
          planOpenedDate: company.homeStatus.openedDate,
          planExpirationDate: company.homeStatus.dueDate,
          taxId: company.taxId,
          products: company.homeStatus.productStatus
            .filter((f) => [ProductStatusEnum.Delivered, ProductStatusEnum.Active].includes(f.status))
            .map((p) => p.product),
          aclProducts: company.aclProducts,
          isBranch: [OfficeType.BRANCH, OfficeType.FOREIGN_BRANCH].includes(company.officeType),
        })),
      });

      if (posthog.__loaded) {
        posthog.identify(user.email, {
          email: user.email,
        });
        posthog.startSessionRecording();
      }

      if (window._cio && window._cio?.pageHasLoaded) {
        window._cio.identify({
          id: user.email,
        });
      }
    }
  }, [posthog, user]);

  const cookieApiHeader = getTranslated(CookieApiHeader, null, language);

  return (
    <>
      <Head>
        <meta httpEquiv="Content-type" content="text/html; charset=UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1" />
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <RouterContextProvider router={router} languageParam={languageParam}>
        <I18nextProvider i18n={getI18nInstance(i18n, language)}>
          <InternationalizationContextProvider
            country={country}
            language={language}
            host={host}
            geo={geo}
            selectedLanguage={selectedLanguage}
          >
            <IsLoggedInContextProvider isLoggedIn={isLoggedIn}>
              <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
              <DefaultSeo
                titleTemplate="%s | Dunsguide"
                openGraph={{
                  type: 'website',
                }}
              />
              <PostHogProvider client={posthog}>
                <Component {...restPageProps} />
              </PostHogProvider>
            </IsLoggedInContextProvider>
          </InternationalizationContextProvider>
        </I18nextProvider>
      </RouterContextProvider>

      <Script
        id="cookieApiHeader"
        dangerouslySetInnerHTML={{
          __html: cookieApiHeader,
        }}
      />
      <Script src="//cdn.iubenda.com/cs/beta/iubenda_cs.js" />
    </>
  );
};

MyApp.propTypes = propTypes;

export default wrapper.withRedux(MyApp);
